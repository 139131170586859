import React, {ReactElement, FC} from "react";

const Header:FC<any> = ():ReactElement => {
 return(
   <>
    <div className="flex justify-center w-full h-20">
         <div className="flex w-11/12 justify-between border-b-2 border-solid border-gray-100 pb-2">
            <div className="my-2 items-start">
               <img style={{height: "60px", marginTop:"5px"}} alt="NurseMoney Logo" src='https://static.wixstatic.com/media/a163ae_e40620eb5b8e4af0b41c65360b2f0d02~mv2.png/v1/crop/x_0,y_47,w_500,h_406/fill/w_184,h_150,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Copy%20of%20Nurse%20Money%20Logo%20Pack%20(1).png'/>
            </div>
         
            <div className="my-auto float-right mr-5">
               <p className="text-xs text-right" style={{color: "gray", fontSize: "8px"}}>Powered By</p>
               <img style={{height: "29px", width: "104px"}} alt="CarClarity Logo" src="https://widgets.carclarity.com.au/img/CarClarity.2d0241f0.svg"/>
            </div>
         </div>
   </div>
    </>
 )
}

export default Header